button.btn.btn-light.btn--rounded.btn-icon.icon-left.btn-sm.m-1 { 
	align-items: center;
	appearance: none;
	background-color: #ffffff;
	border: 1px solid transparent;
	border-bottom-left-radius: 200px;
	border-bottom-right-radius: 200px;
	border-radius: 200px;
	border-top-left-radius: 200px;
	border-top-right-radius: 200px;
	box-shadow: rgba(41, 41, 59, 0.15) 0px 10px 20px;
	box-sizing: border-box;
	color: #212529;
	display: inline-flex;
	flex-wrap: wrap;
	font-family: inherit;
	font-size: 12.9px;
	font-weight: normal;
	justify-content: center;
	line-height: 12.9px;
	margin: 1rem;
	overflow: visible;
	padding: 5px 10px;
	text-align: center;
	text-transform: capitalize;
	transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
}
button.btn.btn-light.btn--rounded.btn-icon.icon-left.btn-sm.m-1:focus { 
	box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 0px inset, rgba(0, 0, 0, 0.075) 0px 1px 1px, rgba(255, 255, 255, 0.5) 0px 0px 0px 0.2rem;
	outline: 0px;
	text-decoration: none;
}
button.btn.btn-light.btn--rounded.btn-icon.icon-left.btn-sm.m-1:hover { 
	background-color: #ececec;
	border-color: rgb(230, 230, 230);
	color: #212529;
	text-decoration: none;
}
button.btn.btn-light.btn--rounded.btn-icon.icon-left.btn-sm.m-1:disabled { 
	background-color: #ffffff;
	border-color: rgb(255, 255, 255);
	box-shadow: none;
	color: #212529;
	cursor: pointer;
	opacity: 0.65;
}
button.btn.btn-light.btn--rounded.btn-icon.icon-left.btn-sm.m-1:active { 
	background-color: #e6e6e6;
	background-image: none;
	border-color: rgb(223, 223, 223);
	box-shadow: rgba(0, 0, 0, 0.125) 0px 3px 5px inset;
	color: #212529;
}
button.btn.btn-light.btn--rounded.btn-icon.icon-left.btn-sm.m-1::before { 
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-family: fontello;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1em;
	margin-left: 0.2em;
	margin-right: 0.2em;
	text-align: center;
	text-decoration: inherit;
	text-transform: none;
	width: 1em;
}
@media screen and (prefers-reduced-motion: reduce) { 
	button.btn.btn-light.btn--rounded.btn-icon.icon-left.btn-sm.m-1 { 
		transition: none 0s ease 0s;
	}
}
@media print { 
	button.btn.btn-light.btn--rounded.btn-icon.icon-left.btn-sm.m-1 { 
		box-shadow: none !important;
		text-shadow: none !important;
	}

    small.pink.d-inline-block.text-center.cursor-pointer.tag-remove-icon { 
		box-shadow: none !important;
		text-shadow: none !important;
	}

    span.teal { 
		box-shadow: none !important;
		text-shadow: none !important;
	}
}

small.pink.d-inline-block.text-center.cursor-pointer.tag-remove-icon { 
	box-sizing: border-box;
	color: #212529;
	cursor: pointer;
	display: inline-block;
	font-family: Poppins, Mirza, sans-serif;
	font-size: 10.32px;
	font-weight: 400;
	line-height: 10.32px;
	text-align: center;
	text-transform: capitalize;
	white-space: nowrap;
}

span.teal { 
	box-sizing: border-box;
	color: #212529;
	cursor: pointer;
	display: block;
	font-family: Poppins, Mirza, sans-serif;
	font-size: 12.9px;
	line-height: 12.9px;
	margin-right: 6px;
	text-align: center;
	text-transform: capitalize;
	white-space: nowrap;
}
